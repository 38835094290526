@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

$accent-color: #8742df;
$accent-color-dark: darken($accent-color, 15%);
$white: #fff;
$dark: #333;
$gray: #b0b0b0;
$font-family-primary: "Roboto", sans-serif;
$font-family-secondary: "Lato", sans-serif;

* {
  margin: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $white;
  color: $dark;
  font-family: $font-family-primary;
  padding: 0 10px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 450px;
  }

  &__title {
    margin-top: 2rem;
    font-size: 2rem;
    align-self: flex-start;
  }

  &__text {
    font-size: 1rem;
    font-family: $font-family-secondary;
    text-align: left;
  }

  &__link {
    font-size: 1rem;
    color: $accent-color;
    font-family: $font-family-secondary;

    &:hover {
      color: $accent-color-dark;
    }
  }

  &__disclaimer {
    font-size: 1rem;
    margin: 3rem 0 10px;
    font-family: $font-family-secondary;
    color: $gray;
  }

  &__accent-color {
    color: $accent-color;
  }

  &__socials {
    display: flex;
    justify-content: center;
  }

  &__social-link {
    margin: 0 0.5rem;
  }

  &__social-icon {
    width: 20px;

    &:hover {
      filter: brightness(75%) saturate(140%) hue-rotate(-5deg);
    }
  }

  &__footer {
    position: absolute;
    bottom: 10px;
  }
}